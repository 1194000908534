<template>
    <div class="faq">
        <div class="mian">
            <div class="inner_nav clearfix">
                <div class="fl inner_nav_l">当前位置：<span @click="backFun">首页</span> > 常见问题</div>
            </div>
            <div class="faq_box" v-if="problem.length>0">
                <div class="faq_item" v-for="(item,index) in problem" :key="index">
                    <div class="faq_item_problem">{{'Q'+item.index+'、'+item.title}}</div>
                    <div class="faq_item_answer">{{item.content}}</div>
                </div>
            </div>
            <el-empty v-else style="margin-bottom:100px" description="暂无数据"></el-empty>
        </div>
    </div>
</template>

<script>
export default {
    name:'faq',
    data(){
        return{
            problem:[]
        }
    },
    mounted(){
        this.$parent.routerIndex = 0;
        this.$parent.showIndex = 1;
        this.initFun()
    },
    methods:{
        initFun(){
            var that = this;
            this.$api.POST(this.$face.problemIndex,'',function(res){
                res.data.problem.forEach((item,index) => {
                    item.index = index+1
                });
                that.problem = res.data.problem
            })
        },
        //面包屑
        backFun(){
            localStorage.setItem('clearAll',1)
            this.$router.push({name:'refresh',query:{usernext:'/'}})
        },
    }
}
</script>

<style scoped>
.ranking_detail{
    background-color: #f6f6f6;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}


.faq_box{
    width: 100%;
	background-color: #ffffff;
	border-radius: 15px;
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 149px;
}

.faq_item{
    padding: 30px 20px 37px;
    border-bottom: 1px solid #eeeeee;
}
.faq_item:last-child{
    border: none;
}
.faq_item_problem{
    font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 48px;
	letter-spacing: 0px;
	color: #333333;
}
.faq_item_answer{
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 34px;
	letter-spacing: 0px;
	color: #666666;
}
</style>